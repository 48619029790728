<template>
    <div class="main-box">
        <project-info :id="id" :tabs="tabs" :app_lists="app_lists" :project="project"></project-info>
    </div>
</template>

<script>
    import ResourceDialog from "./components/ResourceDialog";
    import ProjectInfo from "@/components/material/ProjectInfo";

    export default {
        name: "MaterialProjectDetail",
        components: {
            ResourceDialog,
            ProjectInfo
        },
        created() {
            this.projectInfo();
        },
        data() {
            return {
                id: this.$route.query.id ? Number(this.$route.query.id) : 0,
                tabs: [
                    '项目详情',
                ],
                project: {
                    project_title: '',
                    project_ability: [],
                    project_attrs: [],
                    project_evaluate: [],
                    project_knowledge_link: [],
                    project_sucai: [],
                    project_task: [],
                    project_duration: 0,
                    project_difficulty: 0
                },
                app_lists: []
            }
        },
        methods: {
            projectInfo() {
                this.$http.axiosGetBy(this.$api.getProjectDetail, { id: this.id }, (res) => {
                    if (res.code === 200) {
                        this.project = Object.assign({}, res.data);
                        this.app_lists = [].concat(res.data.app_lists);
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err)
                })
            }

        }
    }
</script>

<style scoped lang="scss">
    .main-box {
        width: 1200px;
        margin: 20px auto;
    }
</style>